<template>
    <div id="about">
        <div id="about-box">
            <div id="about-close" @click="closeBox"></div>
            <div id="about-box-top"></div>
            <div id="about-box-bottom"></div>

            <perfect-scrollbar id="about-box-content" ref="scrollbar-text">

                <div v-if="getLang === 'de'" class="col-12 about-box-title text-center">Impressum</div>
                <div v-if="getLang === 'en'" class="col-12 about-box-title text-center">Imprint</div>

                <div  v-if="getLang === 'de'" class="col-12 abc-text " style="text-align: center">
                    <strong>Angaben gemäß § 5 TMG:</strong> <br>
                    <br>
                    Sierra Madre GmbH<br>
                    Rohrstrasse 26<br>
                    D 58093 Hagen<br>
                    Germany<br>
                    <br>
                    <strong>Vertreten durch:</strong><br>
                    Guido Klaumann<br>
                    Timo Fischer<br>
                    Marcus da Costa<br>
                    <br>
                    <strong>Kontakt:</strong><br>
                    Telefon: (+49) 2331 / 3 77 56 0<br>
                    Telefax: (+49) 2331 / 3 77 56 30<br>
                    E-Mail: <a href="mailto:info@sierra-madre.de">info@sierra-madre.de</a><br>
                    <br>
                    <strong>Registereintrag:</strong><br>
                    Eintragung im Handelsregister.<br>
                    Registergericht:Hagen<br>
                    Registernummer: HRB 3142<br>
                    <br>
                    <strong>Umsatzsteuer-ID:</strong><br>
                    Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:<br>
                    USt.-Nr. / VAT: DE 170836 154<br>
                    <br>
                    <strong>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</strong><br>
                    Guido Klaumann<br>
                </div>

                <div  v-if="getLang === 'en'" class="col-12 abc-text " style="text-align: center">
                    <strong>Disclosures in accordance with §5 Telemedia Act:</strong> <br>
                    <br>
                    Sierra Madre GmbH<br>
                    Rohrstrasse 26<br>
                    D-58093 Hagen<br>
                    Germany<br>
                    <br>
                    <strong>Represented by:</strong><br>
                    Guido Klaumann<br>
                    Timo Fischer<br>
                    Marcus da Costa<br>
                    <br>
                    <strong>Contact:</strong><br>
                    Phone: (+49) 2331 / 3 77 56 0<br>
                    Fax: (+49) 2331 / 3 77 56 30<br>
                    Email: <a href="mailto:info@sierra-madre.de">info@sierra-madre.de</a><br>
                    <br>
                    <strong>Register entry:</strong><br>
                    Entry in the commercial register.<br>
                    Register court: :Hagen<br>
                    Register number: HRB 3142<br>
                    <br>
                    <strong>VAT number:</strong><br>
                    Value Added Tax number in accordance with §27 a VAT Act:<br>
                    VAT: DE 170836 154<br>
                    <br>
                    <strong>Responsible for the content in accordance with §55 paragraph 2 Interstate Broadcasting Agreement:</strong><br>
                    Guido Klaumann<br>
                </div>

            </perfect-scrollbar>

        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "ImpressumView",
        metaInfo: {
            title: 'Impressum',
        },
        mounted() {
            this.$store.dispatch('makeLoaderInactive')
                .then( () => {});
        },
        computed: {
          ...mapGetters(['getLang'])
        },
        methods: {
            closeBox(){
                this.$router.push({ name: ( this.$store.getters.getLang === 'de' ? 'Store' : 'StoreENG' ) } );
            }
        },
        beforeRouteLeave(to, from, next){
            this.$store.dispatch('makeLoaderActive')
                .then( () => next() );
        }
    }
</script>

<style lang="less" >

</style>

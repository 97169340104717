<template>
    <div id="about">
        <div id="about-box" class="events-box">
            <div id="about-close" @click="closeBox"></div>
            <div id="about-box-top"></div>
            <div id="about-box-bottom"></div>

            <router-link id="store-button" :class="{ english: getLang === 'en'}" :to="{ name: getLang === 'de' ? 'Store' : 'StoreENG'}"></router-link>

            <perfect-scrollbar id="about-box-content" ref="scrollbar-text">

                <div v-if="getLang === 'de'" class="col-12 about-box-title text-center">Termine</div>
                <div v-if="getLang === 'en'" class="col-12 about-box-title text-center">Events</div>

                <div class="col-12 abc-text " >
                    <div class="row">
                        <div class="col-12 col-xl-8">
                            <div class="row no-gutters">
                                <div v-if="getLang === 'de'" class="col-12">Übersicht aller Termine, Veranstaltungen und Events rund um Remedy.</div>
                                <div v-if="getLang === 'en'" class="col-12">Overview of all dates, and events related to Remedy. </div>

                                <div v-if="events.length > 0">
                                    <div class="event-box" :class="{ borderBottom: ++index !== events.length}" v-for="(item, index) in events" :key="item.ID">
                                        <div class="event-title">{{ item.post_title }} - {{ item.post_date }}</div>
                                        <div class="event-content" v-html="item.post_content"></div>
                                    </div>
                                </div>
                                <div id="no-events" v-else>
                                    {{ getLang === 'de' ? 'Momentan sind keine Termine zu finden.' : 'There are currently no dates for events.' }}
                                </div>
                            </div>
                        </div>

                        <div class="d-none d-xl-block col-xl-4">
                            <img src="../assets/images/events-bild.jpg" alt="">
                        </div>
                    </div>
                </div>



            </perfect-scrollbar>

        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    import { _axios } from "../main"

    export default {
        name: "ImpressumView",
        metaInfo: {
            title: 'Impressum',
        },
        data(){
            return {
                events: []
            }
        },
        computed: {
            ...mapGetters(['getLang'])
        },
        mounted() {
            this.$store.dispatch('makeLoaderInactive')
                .then( () => {});
        },
        methods: {
            closeBox(){
                this.$router.push({ name: ( this.$store.getters.getLang === 'de' ? 'Store' : 'StoreENG' ) } );
            }
        },
        beforeRouteLeave(to, from, next){
            this.$store.dispatch('makeLoaderActive')
                .then( () => next() );
        },
        beforeRouteEnter(to, from, next){
            _axios.get('events/')
                .then( ( res ) => {
                    next( vm => {
                        vm.events = res.data;
                    });
                })
                .catch(() =>{
                    console.log('server error');
                    next();
                })
        }
    }
</script>

<style lang="less" >
    @import "../assets/less/vars";

    #store-button{
        position: absolute;

        z-index: 5;

        width: 204px;
        height: 57px;

        background-image: url("../assets/images/cta-to-store.png");
        background-size: cover;

        transition: 0.3s all ease-in-out;

        transform: translateY(105%);
        &.english{
            background-image: url("../assets/images/cta-to-store-en.png");
        }
        &:hover{
            transform: translateY(105%) scale(1.05);
        }
    }

    #no-events{
        margin-top: 30px;
        padding-bottom: 20px;
        font-family: "docktrinregular";
        font-size:36px;
        line-height: 1;
        color: @themeColor2;
    }

    .event-box{
        margin-top: 30px;

        &.borderBottom{
            border-bottom: 3px dashed @themeColor2;
        }

        .event-title{
            font-family: "docktrinregular";
            font-size:36px;
            line-height: 1;
            color: @themeColor2;
        }

        .event-content{
            p{
                margin-top: 0;
            }

            a{
                color: @themeColor2;
            }
        }
    }
</style>

<template>
    <div id="about">
        <div id="about-box">
            <div v-if="inProgress" class="ajax-bg">
                <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
            </div>
            <div id="about-close" @click="closeBox"></div>
            <div id="about-box-top"></div>
            <div id="about-box-bottom"></div>

            <perfect-scrollbar id="about-box-content" style="padding-left: 30px; padding-right: 30px;" ref="scrollbar-text" :options="{ suppressScrollX : true}">

                <div class="row justify-content-center">
                    <div class="col-12 about-box-title text-center">{{ getLang === 'de' ? 'kontaktiere unsere Apotheker & Chemiker' : 'Contact our pharmacists & chemists'}}</div>
                    <div class="col-12 col-xl-6 abc-text " >
                        Sierra Madre GmbH<br>
                        Rohrstrasse 26, D 58093 Hagen, Germany<br>
                        <br>
                        <span class="head">E-Mail:</span> <a href="mailto:info@sierra-madre.de">info@sierra-madre.de</a><br>
                        <br>
                        <span class="head">Phone:</span> (+49) 02331 / 3 77 56 – 0<br>
                        <br>
                        <span class="head">Fax:</span> (+49) 02331 / 3 77 56 – 30<br>
                        <br>
                        <span class="head" v-if="getLang === 'de'">Bürozeiten:</span><span v-if="getLang === 'en'" class="head">Office time:</span>  Mo. – Do. 07.00 bis 18.00 Uhr<br>
                        <span class="head"></span> Fr. 07.00 bis 16.00 Uhr
                    </div>
                    <div id="kontakt-formular" class="col-12 col-xl-6 abc-text " style="text-align: center">
                        <input v-model="fullname" :class="{ bordered: ($v.fullname.$invalid && $v.fullname.$dirty) }" @input="$v.fullname.$touch" type="text" name="name" placeholder="Name">
                        <input v-model="email" :class="{ bordered: ($v.email.$invalid && $v.email.$dirty) }" @input="$v.email.$touch" type="email" name="email" :placeholder="getLang === 'de' ? 'E-Mail Adresse' : 'E-Mail'">
                        <input v-model="telefon" :class="{ bordered: ($v.telefon.$invalid && $v.telefon.$dirty) }" @input="$v.telefon.$touch" type="text" name="telefon"  :placeholder="getLang === 'de' ? 'Telefonnummer' : 'Phone Number'">
                        <textarea v-model="message" :class="{ bordered: ($v.message.$invalid && $v.message.$dirty) }" @input="$v.message.$touch"  name="nachricht" :placeholder="getLang === 'de' ? 'Ihre Nachricht' : 'Message'"></textarea>
                        
                        <div class="row" style="padding-top: 10px;">
                            <div class="col-auto">
                                <input v-model="checkbox" ref="checkbox" type="checkbox">
                            </div>
                            <div class="col checkbox-info">
                                Zustimmung zur Datenspeicherung lt. DSGVO: Ich bin mit der Speicherung und Verarbeitung meiner Daten durch diese Website einverstanden. Sie können Ihre Kommentare jederzeit wieder löschen lassen. Detaillierte Informationen finden Sie in unserer Datenschutzerklärung
                            </div>
                        </div>
                    </div>
                    <div class="col-auto" style="padding-top: 25px;">
                        <div @click="send_form" id="kontakt-send">
                            <div class="normal"></div>
                        </div>
                    </div>
                </div>

            </perfect-scrollbar>

        </div>
    </div>
</template>

<script>
    import { required, email } from 'vuelidate/lib/validators';
    import {mapGetters} from "vuex";

    export default {
        name: "KontaktView",
        data(){
            return {
                fullname: '',
                email: '',
                telefon: '',
                message: '',
                checkbox: '',
                inProgress: false,
            }
        },
        metaInfo: {
            title: 'Kontakt',
        },
        mounted() {
            this.$store.dispatch('makeLoaderInactive')
                .then( () => {});
        },
        computed: {
          ...mapGetters(['getLang'])
        },
        methods: {
            closeBox(){
                this.$router.push({ name: ( this.$store.getters.getLang === 'de' ? 'Store' : 'StoreENG' ) } );
            },
            send_form(){
                this.$v.$touch();

                if( !this.checkbox ){
                    this.$refs.checkbox.required  = true;
                }

                if( !this.$v.$invalid && this.checkbox){
                    this.inProgress = true;

                    const params = new URLSearchParams();
                    params.append('fullname', this.fullname);
                    params.append('email', this.email );
                    params.append('telefon', this.telefon );
                    params.append('body', this.message );



                    this.axios.post( 'send-email', params )
                        .then( (  ) => {
                            // alert('Vielen Dank für deine Mitteilung. Sie wurde versandt.');
                            alert('Vielen Dank für deine Nachricht. Das Apothekerteam wird sich schnellstmöglich dir melden!');
                            this.$v.$reset();
                            this.fullname = '';
                            this.email = '';
                            this.message = '';
                            this.telefon = '';

                            this.inProgress = false;
                        })
                        .catch( () => {
                            alert('Server Error');

                            this.inProgress = false;
                        })
                }
            }
        },
        beforeRouteLeave(to, from, next){
            this.$store.dispatch('makeLoaderActive')
                .then( () => next() );
        },
        validations: {
            fullname: {
                required
            },
            telefon: {
                required
            },
            message: {
                required
            },
            email: {
                required,
                email
            },
            checkbox: {
                required
            }
        }
    }
</script>

<style lang="less" >
    @import "../assets/less/vars";

    .head{
        display: inline-flex;
        width: 110px;
    }

    .bordered{
        border-color: red !important;
    }

    input, textarea{
        width: 100%;
        padding: 15px;
        margin-top: -2px;

        background: none;

        font-family: "Palatino-Roman";
        font-size: 16px;
        color: @themeColor2;

        border: 4px solid @themeColor1;
    }
    textarea{
        height: 190px;
    }

    .checkbox-info{

        text-align: left;
        font-size: 12px;
    }

    #kontakt-send{
        width: 205px;
        height: 57px;

        cursor: pointer;

        .normal{
            width: 100%;
            height: 100%;

            background: url("../assets/images/cta-absenden.png") center center no-repeat;
            background-size: cover;
        }
    }

    #about-box{
        position: relative;

        .ajax-bg{
            position: absolute;

            display: flex;
            justify-content: center;
            align-items: center;

            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            z-index: 3;

            background: rgba(0,0,0,0.4);
        }
    }

    .lds-ring {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }
    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
        margin: 8px;
        border: 8px solid #fff;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent;
    }
    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }
    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }
    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }
    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

</style>
